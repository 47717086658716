import React, { FC } from 'react';
import { CustomMenuItemProps, useGridMenuItem } from '@ag-grid-community/react';
import { Column, RowNode } from '@ag-grid-community/core';

interface DataGridContextMenuItemProps extends CustomMenuItemProps {
  node: RowNode;
  column: Column;
  value: unknown;
}

export const DataGridContextMenuItem: FC<DataGridContextMenuItemProps> = ({
  name,
  action,
  subMenu,
  menuItemParams,
  context,
  api,
  node,
  column,
  value,
}) => {
  useGridMenuItem({
    configureDefaults: () => true,
  });

  return (
    <div
      data-test={menuItemParams.testId}
      onClick={() => action?.({ context, api, node, column, value })}
    >
      <span className="ag-menu-option-part ag-menu-option-text">{name}</span>
      {!!subMenu && (
        <span className="ag-menu-option-part ag-menu-option-popup-pointer">
          <span className="ag-icon ag-icon-small-right" role="presentation" />
        </span>
      )}
    </div>
  );
};
