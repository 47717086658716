import { AgGridReact } from '@ag-grid-community/react';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Subscription } from 'rxjs';
import { GridReadyEvent, IGetRowsParams } from '@ag-grid-community/core';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import {
  DataFetcherParams,
  DataParserResponse,
} from '../../../classes/async-connector';

import { InternalDataGridProps } from './data-grid-props';

export const DataGridInfinite = React.forwardRef(
  // @ts-expect-error Needs new type
  (
    {
      className,
      columns,
      dataSource,
      name,
      noResultsComponent,
      noRowsOverlayComponentParams,
      getContextMenuItems,
      onSelectionChanged,
      sorting,
      allowContextMenu = false,
    }: InternalDataGridProps,
    gridRef: React.Ref<AgGridReact> & { current: AgGridReact }
  ) => {
    const [gridReady, setGridReady] = useState(false);
    const unsubscribeListener = useRef<Subscription>();
    const updateGrid = (response: unknown, params: IGetRowsParams) => {
      if (dataSource) {
        const parsedResults = dataSource.parseData(
          response
        ) as DataParserResponse;
        if (parsedResults.totalPages === 0 || !parsedResults.data) {
          gridRef.current?.api?.showNoRowsOverlay();
          params.successCallback([], 0);
        } else {
          gridRef.current?.api?.hideOverlay();
          params.successCallback(
            parsedResults.data,
            parsedResults.totalPages === parsedResults.currentPage
              ? (parsedResults.totalPages - 1) * dataSource.getPageSize() +
                  parsedResults.data.length
              : -1
          );
        }
        setGridReady(true);
      }
    };
    const constructDataSource = () => ({
      getRows: (params: IGetRowsParams) => {
        if (dataSource) {
          const askingPage =
            Math.floor(params.startRow / dataSource.getPageSize()) + 1;
          dataSource
            .getData({
              sort: Object.keys(sorting).reduce(
                (acc, sortProp) => {
                  acc[sortProp] = sorting[sortProp];
                  return acc;
                },
                {} as DataFetcherParams['sort']
              ),
              page: askingPage,
            })
            .then(response => {
              if (!unsubscribeListener.current) {
                unsubscribeListener.current = dataSource.onInvalidate(() => {
                  gridRef.current?.api.refreshInfiniteCache();
                });
              }
              updateGrid(response, params);
            });
        }
      },
    });
    const onGridReady = useCallback((readyParams: GridReadyEvent<unknown>) => {
      readyParams.api.setGridOption('datasource', constructDataSource());
    }, []);

    useEffect(() => {
      if (gridRef.current) {
        gridRef.current.api?.setGridOption('datasource', constructDataSource());
      }

      return () => {
        if (unsubscribeListener.current) {
          unsubscribeListener.current.unsubscribe();
          unsubscribeListener.current = undefined;
        }
      };
    }, [dataSource, sorting]);

    const defaultColDef = useMemo(() => {
      return {
        autoHeight: !dataSource && true,
        sortable: false,
      };
    }, []);

    return (
      <div
        className={AttrsHelper.formatClassname(
          className,
          'ag-theme-quartz ag-theme-axiom theme-infinite'
        )}
        data-test={name}
        data-ready={gridReady}
      >
        <AgGridReact
          enableCellTextSelection
          reactiveCustomComponents
          ref={gridRef}
          suppressContextMenu={!allowContextMenu}
          getContextMenuItems={getContextMenuItems}
          allowContextMenuWithControlKey
          columnDefs={columns}
          defaultColDef={defaultColDef}
          rowBuffer={0}
          rowSelection="single"
          rowModelType="infinite"
          cacheBlockSize={dataSource?.getPageSize()}
          suppressDragLeaveHidesColumns
          maxConcurrentDatasourceRequests={1}
          noRowsOverlayComponent={noResultsComponent}
          noRowsOverlayComponentParams={noRowsOverlayComponentParams}
          onGridReady={onGridReady}
          suppressCellFocus
          onSelectionChanged={
            onSelectionChanged
              ? () => {
                  if (gridRef.current) {
                    onSelectionChanged(gridRef.current.api.getSelectedRows());
                  }
                }
              : undefined
          }
        />
      </div>
    );
  }
);
