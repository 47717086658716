import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AgGridReact } from '@ag-grid-community/react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';
import { Layout } from '../Layout/Layout';
import { Gutter } from '../Gutter/Gutter';
import { IconButton } from '../../element/Button/IconButton';
import { Pagination } from '../../element/Pagination/Pagination';
import { Loader } from '../../element/Loader/Loader';
import { DataFetcherParams } from '../../../classes/async-connector';

import { InternalDataGridProps } from './data-grid-props';

type PaginationState = {
  data?: Array<Record<string, unknown>>;
  totalPages: number;
  currentPage: number;
  isLoading: boolean;
};
export const DataGridPagination = React.forwardRef(
  (
    {
      className,
      columns,
      dataSource,
      name,
      noResultsComponent,
      noRowsOverlayComponentParams,
      getContextMenuItems,
      onInternalSortChange,
      onSelectionChanged,
      sorting,
      allowContextMenu = false,
    }: InternalDataGridProps<unknown>,
    gridRef: React.ForwardedRef<AgGridReact>
  ) => {
    const defaultColDef = useMemo(() => {
      return {
        autoHeight: !dataSource && true,
        sortable: false,
      };
    }, []);

    const [paginationState, setPaginationState] = useState<PaginationState>({
      data: [] as Array<Record<string, unknown>>,
      totalPages: 1,
      currentPage: 1,
      isLoading: true,
    });

    const internalDataSource = {
      getRows: (pagState: PaginationState) => {
        if (dataSource) {
          const askingPage = pagState.currentPage;
          dataSource
            .getData({
              sort: Object.keys(sorting).reduce(
                (acc, sortProp) => {
                  acc[sortProp] = sorting[sortProp];
                  return acc;
                },
                {} as DataFetcherParams['sort']
              ),
              page: askingPage,
            })
            .then(response => {
              const parsedResults = dataSource.parseData(response);
              setPaginationState({
                totalPages: parsedResults.totalPages,
                currentPage: parsedResults.currentPage,
                data: parsedResults.data as Array<Record<string, unknown>>,
                isLoading: false,
              });
            });
        }
      },
    };

    const onGridReady = useCallback(() => {
      internalDataSource.getRows(paginationState);
    }, []);

    useEffect(() => {
      internalDataSource.getRows({
        data: [],
        totalPages: 0,
        currentPage: 1,
        isLoading: true,
      });
    }, [dataSource, sorting]);

    const onPageChange = (newPage: number) => {
      internalDataSource.getRows({
        ...paginationState,
        currentPage: newPage,
        isLoading: true,
      });
    };

    return (
      <div
        className={AttrsHelper.formatClassname(
          className,
          'ag-theme-quartz ag-theme-axiom theme-pagination'
        )}
        data-test={name}
        data-ready={dataSource?.hasFetched()}
      >
        {!!paginationState.isLoading && (
          <Loader type="spinner" expand="cover" />
        )}
        <AgGridReact
          enableCellTextSelection
          reactiveCustomComponents
          ref={gridRef}
          suppressContextMenu={!allowContextMenu}
          getContextMenuItems={getContextMenuItems}
          allowContextMenuWithControlKey
          animateRows={false}
          defaultColDef={defaultColDef}
          suppressCellFocus
          columnDefs={columns}
          maxConcurrentDatasourceRequests={1}
          noRowsOverlayComponent={noResultsComponent}
          noRowsOverlayComponentParams={noRowsOverlayComponentParams}
          rowModelType="clientSide"
          onGridReady={onGridReady}
          suppressDragLeaveHidesColumns
          domLayout="autoHeight"
          rowData={paginationState.data}
          rowSelection="single"
          onSortChanged={onInternalSortChange}
          onSelectionChanged={
            onSelectionChanged
              ? () => {
                  onSelectionChanged?.();
                }
              : undefined
          }
        />
        <Gutter bottom="8px" />
        <Layout position="right">
          <Pagination
            currentPage={paginationState.currentPage}
            totalPages={paginationState.totalPages}
            nextButton={
              <IconButton
                icon="arrow-right"
                variation="minimal"
                pattern="secondary"
                onClick={() => {
                  onPageChange(paginationState.currentPage + 1);
                }}
                name="pagination-next-button"
              />
            }
            prevButton={
              <IconButton
                icon="arrow-left"
                variation="minimal"
                pattern="secondary"
                onClick={() => {
                  onPageChange(paginationState.currentPage - 1);
                }}
                name="pagination-prev-button"
              />
            }
          />
        </Layout>
      </div>
    );
  }
);
