import { Api } from '@axiom/ui';
import { UserSetting } from '@axiom/validation';

import { EnvUtil } from '../utils/env-util';

class UserSettingsApiClass extends Api {
  constructor() {
    super({
      domain: EnvUtil.apiEnvoyUrl,
    });
  }

  updateUserSetting(body: Partial<UserSetting>) {
    return super.write<{ data: UserSetting }>({
      endpoint: `/user/settings/${body.name}`,
      method: 'PUT',
      body,
    });
  }
}

export const UserSettingsApi = new UserSettingsApiClass();
