import React, { useMemo, useState } from 'react';
import { CandidatesConst } from '@axiom/const';
import {
  AsyncConnector,
  Button,
  Dropdown,
  Form,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
} from '@axiom/ui';
import {
  AhqFindTalentSchema,
  Candidate,
  Opportunity,
  Position,
} from '@axiom/validation';

import {
  CandidateApi,
  readCandidatesResponseType,
} from '../../api/candidate-api';
import { AddTalentUtil } from '../../utils/AddTalentUtil';
import { CandidateUtil } from '../../utils/candidate-util';
import { PositionUtil } from '../../utils/position-util';

import { OpportunityTalentReassignPositionModal } from './OpportunityTalentReassignPositionModal';

const {
  Certifying,
  InDiligence,
  Waitlist,
  PendingActive,
  Active,
  PendingBeach,
  Beach,
  Idle,
} = CandidatesConst.ProfileStatuses;

const noop = () => {};

export const OpportunityTalentFindCandidateModal = ({
  onClose = noop,
  opportunityId,
  positionId,
  positions,
}: {
  onClose: (candidateId: Candidate['id']) => void;
  opportunityId: Opportunity['id'];
  positionId: Position['id'];
  positions: Array<Position>;
}) => {
  const [showReassignModal, setShowReassignModal] = useState(false);

  const candidatesConnector = useMemo(
    () =>
      new AsyncConnector<readCandidatesResponseType>({
        getData: args => {
          return CandidateApi.readCandidates({
            filters: {
              axiomEmployeeType:
                CandidateUtil.getCandidatesListFilterDefaults()
                  .axiomEmployeeType,
              profileStatus: [
                Certifying,
                InDiligence,
                Waitlist,
                PendingActive,
                Active,
                PendingBeach,
                Beach,
                Idle,
              ],
              search: args.search,
            },
            page: args.page,
          });
        },
        parseData: results => {
          const candidates = results.data.map(
            ({ id, calculatedDisplayName: name }) => ({
              id,
              name,
            })
          );

          return {
            currentPage: results.meta.currentPage,
            totalPages: results.meta.pageCount,
            data: candidates,
          };
        },
      }),
    []
  );

  return (
    <Form
      name="FIND_CANDIDATE_FORM"
      schema={AhqFindTalentSchema}
      initialValues={{
        candidateId: undefined,
      }}
      onSubmit={async formData => {
        const { candidateId } = formData;
        if (PositionUtil.isCandidateOnPosition(positions, candidateId)) {
          setShowReassignModal(true);
        } else {
          await AddTalentUtil.handleSubmit(
            opportunityId,
            candidateId,
            positionId
          );
          onClose(candidateId);
        }
      }}
    >
      {({ fireSubmit, values }) => {
        return (
          <Modal name="FIND_CANDIDATE_MODAL">
            <ModalHeader
              name="FIND_CANDIDATE_MODAL_HEADER"
              onClose={() => onClose(null)}
            >
              Search Talent
            </ModalHeader>
            <ModalSection name="FIND_CANDIDATE_MODAL_SECTION" disableScrolling>
              <Dropdown
                name="candidateId"
                options={candidatesConnector}
                displayKey="name"
                valueKey="id"
                placeholder="Type to start searching..."
              />
            </ModalSection>
            <ModalFooter name="FIND_CANDIDATE_MODAL_FOOTER">
              <Button
                onClick={() => onClose(null)}
                name="CANCEL"
                variation="outline"
              >
                Cancel
              </Button>
              <Button type="submit" onClick={fireSubmit} name="SUBMIT">
                Submit
              </Button>
            </ModalFooter>
            {showReassignModal && (
              <OpportunityTalentReassignPositionModal
                onClose={onClose}
                opportunityId={opportunityId}
                candidateId={values.candidateId}
                positionId={positionId}
              />
            )}
          </Modal>
        );
      }}
    </Form>
  );
};
