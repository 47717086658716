import React, { useMemo, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import { CandidatesConst, Event } from '@axiom/const';
import {
  Badge,
  CondensedHeader,
  DataGrid,
  DataGridContextMenuItem,
  AsyncConnector,
  DataGridHeader,
  DateUtil,
  Dropdown,
  Form,
  Gutter,
  IconButton,
  Layout,
  LayoutItem,
  GetContextMenuItems,
  GetContextMenuItemsParams,
} from '@axiom/ui';
import { Candidate, User } from '@axiom/validation';
import { ZodArrayToEnum } from '@axiom/types';

import {
  readUserTalentResponseType,
  UserBenchFilterType,
  UserTalentApi,
} from '../../api/user-talent-api';
import { AppEventItemModalStore } from '../../stores/app-event-item-modal-store';
import { EventSubjectConst } from '../../stores/events-store';
import { TimeCopyUtil } from '../../utils/time-copy-util';

const { ProfileStatuses, DaysUntilAvailabilityPreferencesTriggerAutoIdle } =
  CandidatesConst;

const allowedProfileStatuses = Object.values(ProfileStatuses).filter(
  value =>
    ![
      ProfileStatuses.Alum,
      ProfileStatuses.AlumDNR,
      ProfileStatuses.Rejected,
    ].includes(value)
);

const profileStatusOptions = allowedProfileStatuses.map(value => ({
  label: value,
  value,
}));

const getDaysUntilIdleCopy = (
  daysSinceAvailabilityUpdate: number,
  profileStatus: string
) => {
  if (
    profileStatus === ProfileStatuses.Beach ||
    profileStatus === ProfileStatuses.Waitlist
  ) {
    const daysUntilIdle =
      DaysUntilAvailabilityPreferencesTriggerAutoIdle -
      daysSinceAvailabilityUpdate;
    if (daysUntilIdle < 15) {
      return (
        <Badge
          name="daysUntilIdleBadge"
          background={daysUntilIdle <= 7 ? 'red' : 'yellow'}
        >
          {TimeCopyUtil.getDaysCopy(daysUntilIdle)}
        </Badge>
      );
    }
    return `${daysUntilIdle} Days`;
  }

  if (profileStatus === ProfileStatuses.Idle) {
    return 'Idle';
  }

  return '-';
};

const getDaysSinceLastCheckIn = (
  daysSinceLastCheckIn: number,
  profileStatus: string
) => {
  if (
    [
      ProfileStatuses.Active,
      ProfileStatuses.Beach,
      ProfileStatuses.Waitlist,
      ProfileStatuses.PendingActive,
      ProfileStatuses.PendingBeach,
    ].includes(profileStatus) &&
    daysSinceLastCheckIn > 30
  ) {
    return (
      <Badge
        name="daysSinceLastCheckInBadge"
        background={daysSinceLastCheckIn > 45 ? 'red' : 'yellow'}
      >
        {TimeCopyUtil.getDaysCopy(daysSinceLastCheckIn)}
      </Badge>
    );
  }

  return daysSinceLastCheckIn >= 0
    ? TimeCopyUtil.getDaysCopy(daysSinceLastCheckIn)
    : '-';
};

export const WorkspaceBenchReminders = ({ user }: { user: User }) => {
  const [resultCount, setResultCount] = useState<number>(0);
  const [profileStatusFilter, setProfileStatusFilter] = useState([
    ProfileStatuses.Active,
    ProfileStatuses.Beach,
    ProfileStatuses.Idle,
    ProfileStatuses.PendingActive,
    ProfileStatuses.PendingBeach,
    ProfileStatuses.Waitlist,
  ]);

  const GridConnector = useMemo(
    () =>
      new AsyncConnector<readUserTalentResponseType>({
        getData: args => {
          const newSorts = Object.keys(args.sort) as Array<
            UserBenchFilterType['sort']
          >;
          return UserTalentApi.readUserTalent(
            user.id,
            args.page,
            {
              profileStatus: profileStatusFilter ?? [],
              type: 'owner',
              sort:
                newSorts.reduce((acc, key) => {
                  if (args.sort[key] && !acc) {
                    // @ts-expect-error This needs to be re-looked at
                    acc = key === 'calculatedDisplayName' ? 'lastName' : key;
                  }

                  return acc;
                }, null) ?? undefined,
            },
            Object.values(args.sort)[0]
          );
        },
        parseData: results => {
          setResultCount(results?._meta.resultCount);
          return {
            currentPage: results._meta.currentPage,
            totalPages: results._meta.pageCount,
            data: results.data,
          };
        },
      }),
    [user, profileStatusFilter]
  );

  const getContextMenuItems: GetContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams<Candidate>) => {
      if (params.column?.getColId() === 'contextMenu') {
        return [
          {
            name: 'Record check in',
            menuItem: DataGridContextMenuItem,
            menuItemParams: {
              testId: `record-check-in-${params.node?.data?.id}`,
            },
            action: () =>
              AppEventItemModalStore.openModal(
                EventSubjectConst.Candidate,
                {
                  candidateId: params.node?.data?.id,
                  purpose: Event.PURPOSE_OPTIONS.TALENT_CHECK_IN,
                },
                user.id
              ),
          },
        ];
      }
      return [];
    },
    []
  );

  return (
    <Layout direction="vertical" stretched>
      <LayoutItem topGutter="16px" bottomGutter="16px">
        <Form
          name="WORKSPACE_BENCH_REMINDERS_FILTERS_FORM"
          schema={z.object({
            profileStatus: z
              .array(z.enum(ZodArrayToEnum(allowedProfileStatuses)))
              .nullable(),
          })}
          initialValues={{
            profileStatus: profileStatusFilter,
          }}
          submitOnChange
          onSubmit={changedData => {
            setProfileStatusFilter(changedData.profileStatus);
          }}
        >
          {() => (
            <Dropdown
              label="Filter by profile status"
              name="profileStatus"
              options={profileStatusOptions}
              placeholder="Select a profile status"
              displayKey="label"
              valueKey="value"
            />
          )}
        </Form>
      </LayoutItem>
      <LayoutItem>
        <Gutter vertical="8px">
          <CondensedHeader name="TALENT_COUNT">
            {resultCount} Talent
          </CondensedHeader>
        </Gutter>
      </LayoutItem>
      <LayoutItem stretched>
        <DataGrid<readUserTalentResponseType>
          name="WORKSPACE_BENCH_REMINDERS_DATA_GRID"
          displayMode="infinite"
          dataSource={GridConnector}
          getContextMenuItems={getContextMenuItems}
          allowContextMenu
        >
          <DataGridHeader<Candidate>
            name="calculatedDisplayName"
            displayName="Talent"
            sortingOrder={['asc', 'desc', null]}
            cellRender={({ data }) => {
              return (
                <Link
                  data-test={`talent-detail-link-${data.id}`}
                  to={`/talent-detail/${data.id}`}
                >
                  {data.calculatedDisplayName}
                </Link>
              );
            }}
          />
          <DataGridHeader<Candidate>
            name="profileStatus"
            displayName="Profile status"
            sortingOrder={['asc', 'desc', null]}
          />
          <DataGridHeader<Candidate>
            name="lastCheckIn"
            displayName="Last check in"
            cellRender={({ data }) => {
              const { id, lastCheckIn } = data;
              return lastCheckIn ? (
                <a
                  data-test={`last-check-in-${id}`}
                  onClick={() => {
                    AppEventItemModalStore.openModal(
                      EventSubjectConst.Candidate,
                      lastCheckIn,
                      user.id
                    );
                  }}
                >
                  {DateUtil.displayDayAndDate(lastCheckIn.scheduledDate)}
                </a>
              ) : (
                '-'
              );
            }}
          />
          <DataGridHeader<Candidate>
            name="daysSinceLastCheckIn"
            displayName="Since check in"
            cellRender={({ data: { daysSinceLastCheckIn, profileStatus } }) => {
              return getDaysSinceLastCheckIn(
                daysSinceLastCheckIn,
                profileStatus
              );
            }}
          />
          <DataGridHeader<Candidate>
            name="availabilityPreferencesUpdatedAt"
            displayName="Availability confirmed"
            sortingOrder={['asc', 'desc', null]}
            cellRender={({ data }) => {
              return data.availabilityPreferencesUpdatedAt
                ? DateUtil.displayDayAndDate(
                    data.availabilityPreferencesUpdatedAt
                  )
                : '-';
            }}
          />
          <DataGridHeader<Candidate>
            name="daysUntilIdle"
            displayName="Until idle"
            cellRender={({
              data: { daysSinceAvailabilityUpdate, profileStatus },
            }) => {
              return getDaysUntilIdleCopy(
                daysSinceAvailabilityUpdate,
                profileStatus
              );
            }}
          />
          <DataGridHeader<Candidate>
            name="phoneNumber"
            displayName="Phone number"
            stretched
            cellRender={({ data }) => {
              const phoneNumber = [data.mobilePhone, data.homePhone]
                .filter(Boolean)
                .join(' | ');
              return phoneNumber || '-';
            }}
          />
          <DataGridHeader<Candidate>
            name="personalEmail"
            displayName="Personal email"
            stretched
            sortingOrder={['asc', 'desc', null]}
            cellRender={({ data: { personalEmail } }) => {
              return personalEmail || '-';
            }}
          />
          <DataGridHeader<Candidate>
            name="workEmail"
            displayName="Axiom email"
            stretched
            sortingOrder={['asc', 'desc', null]}
            cellRender={({ data: { workEmail } }) => {
              return workEmail || '-';
            }}
          />
          <DataGridHeader<Candidate>
            name="contextMenu"
            displayName=""
            pinned="right"
            lockPinned
            width={72}
            cellRender={props => {
              const { node, column, value, api } = props;
              const candidateData = node.data;

              return (
                <IconButton
                  name={`bench-reminders-${candidateData?.id}-context-menu`}
                  icon="vertical-ellipsis"
                  variation="minimal"
                  pattern="secondary"
                  onClick={event => {
                    api.showContextMenu({
                      rowNode: node,
                      column,
                      value,
                      x: event.clientX,
                      y: event.clientY,
                    });
                  }}
                />
              );
            }}
          />
        </DataGrid>
      </LayoutItem>
    </Layout>
  );
};
