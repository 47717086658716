import React, { useMemo } from 'react';
import { Grid, GridColumn, GridRow, Gutter, Header4, useApi } from '@axiom/ui';
import { Candidate } from '@axiom/validation';

import UnorderedList from '../UnorderedList/UnorderedList';
import { CandidateOpportunityLegacyApi } from '../../api/candidate-opportunities-legacy-api';
import { DateUtil } from '../../utils/date-util';

export const TalentProfileDetailAvailability = ({
  candidate,
}: {
  candidate: Candidate;
}) => {
  const [{ data: candidateOpportunities }] = useApi(
    CandidateOpportunityLegacyApi.readCandidateOpportunities(candidate.id)
  );
  const [
    engagedAtLowerEscCount,
    wouldAcceptLowerEscCount,
    wasAskedAboutLowerEscCount,
  ] = useMemo(
    () =>
      (candidateOpportunities ?? []).reduce(
        (
          [engagedAtLowerEsc, wouldAcceptLowerEsc, wasAskedAboutLowerEsc],
          curr
        ) => {
          return [
            curr.candidateEngagedAtLowerEsc
              ? engagedAtLowerEsc + 1
              : engagedAtLowerEsc,
            curr.openToLowerEsc ? wouldAcceptLowerEsc + 1 : wouldAcceptLowerEsc,
            curr.openToLowerEsc === null
              ? wasAskedAboutLowerEsc
              : wasAskedAboutLowerEsc + 1,
          ];
        },
        [0, 0, 0]
      ),
    [candidateOpportunities]
  );
  return (
    <>
      <Gutter bottom="16px">
        <Header4 name="Availability_Preferences">
          Work and Availability Preferences
        </Header4>
      </Gutter>
      <Grid columns={2}>
        <GridRow gutterBottom="16px">
          <GridColumn>
            <UnorderedList
              title="Days Since Last Update"
              values={
                (candidate.availabilityPreferencesUpdatedAt ?? null) === null
                  ? null
                  : DateUtil.getDaysSinceNow(
                      candidate.availabilityPreferencesUpdatedAt
                    )
              }
            />
          </GridColumn>
          <GridColumn>
            <UnorderedList
              title="Days In Profile Status"
              values={candidate.daysSinceStatusChange}
            />
          </GridColumn>
        </GridRow>
        <GridRow gutterBottom="16px">
          <GridColumn>
            <UnorderedList
              title="Preferred Weekly Hours"
              values={
                !!(candidate.desiredWeeklyMaxHours || candidate.maxHours) && (
                  <div className="text-align-middle">
                    {candidate.desiredWeeklyMaxHours ?? candidate.maxHours} hrs
                  </div>
                )
              }
            />
          </GridColumn>
          <GridColumn>
            <UnorderedList
              title="Required Minimum Weekly Hours"
              values={
                <div className="text-align-middle">
                  {candidate.minWeeklyHoursPerPosition === null
                    ? '--'
                    : `${candidate.minWeeklyHoursPerPosition} ${
                        candidate.minWeeklyHoursPerPosition === 1 ? 'hr' : 'hrs'
                      }`}
                </div>
              }
            />
          </GridColumn>
        </GridRow>
        <GridRow gutterBottom="16px">
          <GridColumn>
            <UnorderedList
              title="Additional Client"
              values={candidate.isOpenToMultipleClients ? 'Yes' : 'No'}
            />
          </GridColumn>
          <GridColumn>
            <UnorderedList
              title="Other Opportunities"
              values={candidate.isOpenToOtherWork ? 'Yes' : 'No'}
            />
          </GridColumn>
        </GridRow>
        <GridRow gutterBottom="16px">
          <GridColumn>
            <UnorderedList
              title="Notice Period"
              values={candidate.noticePeriod}
            />
          </GridColumn>
        </GridRow>
        <GridRow gutterBottom="16px">
          <GridColumn width={12}>
            <UnorderedList
              title="Work Preferences"
              values={[
                candidate.openToSecondment ? 'Open to Secondments' : null,
                candidate.openToAdviceAndCounsel
                  ? 'Open to Axiom Advice & Counsel opportunities'
                  : null,
                candidate.openToVariableHourlyProjects
                  ? 'Open to Variable Hourly opportunities'
                  : null,
                candidate.openToLargeProjects
                  ? 'Open to Large Projects opportunities'
                  : null,
                candidate.openToPermanentPlacement
                  ? 'Open to Permanent Placement'
                  : null,
              ]
                .filter(preference => preference !== null)
                .join(', ')}
            />
          </GridColumn>
        </GridRow>
        <GridRow gutterBottom="16px">
          <GridColumn width={12} name="OPEN_TO_LOWER_ESC">
            <UnorderedList
              title="Open to Lower ESC"
              showBullets
              values={[
                `Open to lower ESC: ${
                  wasAskedAboutLowerEscCount === 0
                    ? `Unknown. ${candidate.calculatedDisplayName} does not have access to opp feed, or they have never expressed interest through opp feed.`
                    : `${wouldAcceptLowerEscCount} of ${wasAskedAboutLowerEscCount} EOIs made on opp feed`
                }`,
                `Engaged on lower ESC opp: ${engagedAtLowerEscCount} time(s)`,
              ]}
            />
          </GridColumn>
        </GridRow>
      </Grid>
    </>
  );
};
